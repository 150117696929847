import React from "react"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
    return (
        <>
            <Helmet></Helmet>
            <header></header>
            <main>{children}</main>
        </>
    )
}
